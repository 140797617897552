import {
  // create naive ui
  create,
  // component
  NButton,
  NSpin,
  NAlert,
  NSpace,
} from 'naive-ui'

export default create({
  components: [NButton, NSpin, NAlert, NSpace]
})