import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'
import PreQuestionnaire from '@/layout/PreQuestionnaire'
import NotFound from '@/layout/notFound'

const routes = [
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: PreQuestionnaire,
    children: [
      {
        path: '',
        name: 'Apply',
        component: () => import('../components/Apply.vue'),
      },
      {
        path: 'preMovie',
        name: 'PreMovie',
        component: () => import('../components/PreMovie.vue'),
      },
      {
        path: 'consentForm',
        name: 'ConsentForm',
        component: () => import('../components/ConsentForm.vue'),
      },
      {
        path: 'advanceSurvey',
        name: 'AdvanceSurvey',
        component: () => import('../components/AdvanceSurvey.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('../components/Register.vue'),
      },
      {
        path: 'registerConfirm',
        name: 'RegisterConfirm',
        component: () => import('../components/RegisterConfirm.vue'),
      },
      {
        path: 'authRegister/:code',
        name: 'AuthRegister',
        component: () => import('../components/AuthRegister.vue'),
      },
      {
        path: 'preAnswerComplete',
        name: 'PreAnswerComplete',
        component: () => import('../components/PreAnswerComplete.vue'),
      },
      {
        path: 'limitExceeded',
        name: 'LimitExceeded',
        component: () => import('../components/LimitExceeded.vue'),
      },
    ],
  },
  {
    path: '/survey',
    name: 'Survey',
    component: Layout,
    meta: {
      title: '口内細菌・唾液と幸福度調査',
      description: '口内細菌・唾液と幸福度調査',
    },
    children: [
      {
        path: 'registerResearch',
        name: 'RegisterResearch',
        component: () => import('../components/RegisterResearch.vue'),
      },
      {
        path: 'researchConfirm',
        name: 'ResearchConfirm',
        component: () => import('../components/ResearchConfirm.vue'),
      },
      {
        path: 'questions',
        name: 'Questions',
        component: () => import('../components/SurveyQuestions.vue'),
      },
      {
        path: 'answer/:id',
        name: 'Answer',
        component: () => import('../components/Answer.vue'),
      },
      {
        path: 'answerComplete',
        name: 'AnswerComplete',
        component: () => import('../components/AnswerComplete.vue'),
      },
      {
        path: 'declineComplete',
        name: 'DeclineComplete',
        component: () => import('../components/DeclineComplete.vue'),
      },
    ],
  },
  {
    path: '/production',
    name: 'Production',
    component: Layout,
    meta: {
      title: '口内細菌・唾液と幸福度調査',
      description: '口内細菌・唾液と幸福度調査',
    },
    children: [
      {
        path: 'explain',
        name: 'Explain',
        component: () => import('../components/Explain.vue'),
      },
      {
        path: 'answerComplete',
        name: 'ProductionAnswerComplete',
        component: () => import('../components/ProductionAnswerComplete.vue'),
      },
      {
        path: 'productionComplete',
        name: 'ProductionComplete',
        component: () => import('../components/ProductionComplete.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'ログイン',
      description: 'ログイン',
    },
    component: Layout,
    children: [
      {
        path: '',
        name: 'LoginPage',
        component: () => import('../components/Login.vue'),
      },
    ],
  },
  // {
  //   path: '/keyLogin',
  //   name: 'KeyLogin',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../components/KeyLogin.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/ledger',
  //   name: 'Ledger',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../components/Ledger.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/ledgerDetail',
  //   name: 'LedgerDetail',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../components/LedgerDetail.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/transfer',
  //   name: 'Transfer',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../components/Transfer.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/share',
  //   name: 'Share',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../components/Share.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/edit',
  //   name: 'Edit',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../components/Edit.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/result',
  //   name: 'Result',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../components/Result.vue'),
  //     },
  //   ],
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
