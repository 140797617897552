<template>
  <div id="nav">
    <!-- <navbar /> -->
    <app-main />
  </div>
</template>
<script>
import AppMain from './components/AppMain.vue'
// import Navbar from './components/Navbar.vue'
import { resumeFromStatus } from '@/utils/common.js'

export default {
  name: 'PreQuestionnaire',
  components: {
    AppMain,
    // Navbar,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      resumeFromStatus(vm)
    });
  },
}
</script>
