import { ethers } from 'ethers'
import { config } from '@/constants.js'

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isSha256(str) {
  return !!str.match(/^[0-9a-f]{64}$/)
}

export function isMnemonic(str) {
  const words = str.split(' ')
  if (words.length != config.MNEMONIC_WORDS_NUM) return false
  for (const i in words) if (!words[i].match(/^[a-z]+$/)) return false

  let wallet
  try {
    wallet = ethers.Wallet.fromMnemonic(str, "m/44'/60'/0'/0/0")
    if (!wallet.address) throw new Error()
  } catch(e) {
    return false
  }
  return true
}

export function isFloat(str) {
  return !!(/^([1-9]\d*|0)(\.\d+)?$/).test(str) && (str.match( /\./g) || []).length <= 1
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAmount(str) {
  return (/^([1-9]\d*|0)(\.\d+)?$/).test(str) && str > 0
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validSecretkey(str) {
  const spaces = str.trim().match(/\S+/g)

  let words
  if (spaces) {
    words = spaces.length
  } else {
    words = 0
  }
  return words === 12
}

/**
 * 世論調査の回答をバリデーションチェック
 *
 * @param {object} answers
 * @returns {object} questions
 * @returns errors
 */
export function checkSurveyAnswer(answers, questions, nextSurveyQuestionIds) {
  const errors = {}
  const checkSurveyQuestions = []
  for (const i in questions) {
    const question = questions[i]

    if (nextSurveyQuestionIds[question.id] && nextSurveyQuestionIds[question.id][answers[question.id]]) {
      for (const nid of nextSurveyQuestionIds[question.id][answers[question.id]]) {
        checkSurveyQuestions.push(parseInt(nid))
      }
    }
    if (checkSurveyQuestions.indexOf(parseInt(question.id)) >= 0) {
      question.required_flg = 1
    }

    let isBlank, userAnswerId, answerIds = []
    // 回答タイプが自由入力
    if (question.answer_type == config.ANSWER_TYPE.TEXT) {
      isBlank = typeof answers[question.id] == 'undefined' || answers[question.id] === '' || answers[question.id] === null
    } else {
      // 回答タイプが単一選択、複数選択
      for (const j in question.survey_answers) answerIds.push(question.survey_answers[j].id)
      userAnswerId = answers[question.id]
      isBlank = typeof userAnswerId == 'undefined' || userAnswerId === null
    }

    if (question.required_flg == 1 && isBlank) {
      // 必須入力が未入力の場合 エラー
      errors[question.id] = config.ERROR_TYPE.REQUIRED
    } else if ((question.answer_type == config.ANSWER_TYPE.RADIO || question.answer_type == config.ANSWER_TYPE.CHECK) && !isBlank) {
      // 回答タイプがプルダウンかチェックかつ、回答が空でないかつ、回答が選択肢以外の場合エラー
      if (typeof userAnswerId != 'object') userAnswerId = [userAnswerId]
      // 回答タイプが単一選択なのに回答が複数ある場合エラー
      if (question.answer_type == config.ANSWER_TYPE.RADIO && userAnswerId.length > 1) {
        errors[question.id] = config.ERROR_TYPE.INVALID
      } else {
        // 回答に選択肢以外のものが入っている場合エラー
        for (const answerId of userAnswerId) {
          if (answerIds.indexOf(parseInt(answerId)) === -1) {
            errors[question.id] = config.ERROR_TYPE.INVALID
            break
          }
        }
      }
    } else if (typeof userAnswerId !== 'undefined' && userAnswerId !== null && userAnswerId.length > question.limit_answer_num) {
      // 回答の選択可能数が上限を超えていたらエラー
      errors[question.id] = config.ERROR_TYPE.EXCEED_LIMIT
    }
  }
  console.log('errors: ' + JSON.stringify(errors))
  return errors
}
