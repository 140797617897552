<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-navyblue fixed-top">
    <div class="container">
      <span class="navbar-brand text-center px-0 mx-0 w-100">{{ title }}</span>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
        </ul>
      </div>
    </div>
  </nav>
  <section class="app-main">
    <router-view />
  </section>
</template>


<script>
import { config } from '@/constants.js'
export default {
  name: 'AppMain',
  data() {
    return {
      title: config.SITE_NAME,
    }
  },
  created() {
    if (this.$route.meta && this.$route.meta.title) {
      this.title = this.$route.meta.title
    }
  },
}
</script>
