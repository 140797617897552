import { ethers } from 'ethers'
import { aesEncryption } from '@/utils/cipher'

export function createRandomMnemonic() {
  const wallet = ethers.Wallet.createRandom();
  return wallet.mnemonic.phrase;
}

export function createRandomNum() {
  return Math.floor(Math.random() * 1000000000) + 1
}

export function getRandomAddressByMnemonic(mnemonic) {
  const random = createRandomNum();
  const address = getAddressByMnemonicAndIndex(mnemonic, random);
  return { address, random }
}

export function getAddressByMnemonicAndIndex(mnemonic, index) {
  return ethers.Wallet.fromMnemonic(
    mnemonic,
    "m/44'/60'/0'/0/" + index.toString()
  ).address
}

export function makeRequestByPersonalMnemonic(memberCode, personalMnemonic, pinCode) {
  const randomAddress = getRandomAddressByMnemonic(personalMnemonic)
  // リクエスト秘密鍵作成
  const wallet = ethers.Wallet.createRandom()
  const requestMnemonic = wallet.mnemonic.phrase

  const dataToAes = {
    'pin': pinCode,
    'requestSecretKey': requestMnemonic
  }
  const encryptionData = {
    'memberCode': memberCode,
    'index': randomAddress.random,
    'cipher': aesEncryption(JSON.stringify(dataToAes), randomAddress.address)
  }
  return {
    requestData: JSON.stringify(encryptionData),
    requestMnemonic
  }
}

/**
 * 秘密鍵認証用のリクエスト作成
 * @param memberCode {string}
 * @param requestMnemonic {string} ニーモニック
 * @param dataToAes {object} 送信データ
 */
export function makeRequestByAes(memberCode, requestMnemonic, dataToAes) {
  const randomAddress = getRandomAddressByMnemonic(requestMnemonic);
  return {
    'member_code': memberCode,
    'index': randomAddress.random,
    'cipher': aesEncryption(JSON.stringify(dataToAes), randomAddress.address)
  };
}
