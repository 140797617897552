import { config } from '@/constants.js'
import { isFloat } from '@/utils/validate.js'
import DateWithOffset from 'date-with-offset'
import { checkTempId } from '@/api/common.js'
/**
 * msミリ秒間処理を止める
 *
 * @param ms 処理を止める時間(ms)
 * @returns {Promise}
 */
export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, ms)
  });
}

// memberCodeを取得
export function getMemberCode() {
  const member_private_key = sessionStorage.getItem('login_member_code')
  return member_private_key
}

// リクエスト秘密鍵を取得
export function getRequestSecretKey() {
  return sessionStorage.getItem('request_secret_key');
}

export function divideDecimalPoint(str) {
  str = str.toString()
  let rsltArr = []
  const strIdx = str.indexOf('.')
  if (strIdx >= 0) {
    rsltArr = [str.slice(0, strIdx), str.slice(strIdx + 1)]
  } else {
    rsltArr = [str, '']
  }
  rsltArr[0] = trimStartZero(rsltArr[0])
  return rsltArr
}

/**
 * 金額を画面表示用に変換
 * 「10のdecimals乗」で割り算した値を返す
 *
 * @param {string} money 数値
 * @param {number} decimals
 * @return 「10のdecimals乗」で割り算した値
 */
export function MtoV(money, decimals) {
  if (!isFloat(money.toString())) return false

  let rsltArr = divideDecimalPoint(money)

  if (rsltArr[0].length < decimals) {
    const addLength = decimals - rsltArr[0].length
    rsltArr[0] = ('0').repeat(addLength) + rsltArr[0]
  }
  rsltArr[1] = rsltArr[0].slice(-decimals) + rsltArr[1]
  rsltArr[0] = rsltArr[0].slice(0, -decimals)

  if (rsltArr[0] === '') rsltArr[0] = '0'

  rsltArr[1] = trimEndZero(rsltArr[1])

  return rsltArr[0] + (rsltArr[1] !== '' ? '.' + rsltArr[1] : '')
}

/**
 * 金額を登録用に変換
 * 「10のdecimals乗」で掛け算した値を返す
 *
 * @param {string} money 数値
 * @param {number} decimals
 * @return 「10のdecimals乗」で掛け算した値
 */
export function MtoP(money, decimals) {
  if (!isFloat(money.toString())) return false

  let rsltArr = divideDecimalPoint(money)

  if (rsltArr[1].length < decimals) {
    const addLength = decimals - rsltArr[1].length
    rsltArr[1] = rsltArr[1] + ('0').repeat(addLength)
  }
  rsltArr[0] = rsltArr[0] + rsltArr[1].slice(0, decimals)
  rsltArr[1] = rsltArr[1].slice(decimals)

  rsltArr[0] = trimStartZero(rsltArr[0])
  if (rsltArr[0] === '') rsltArr[0] = '0'
  rsltArr[1] = trimEndZero(rsltArr[1])

  return rsltArr[0] + (rsltArr[1] !== '' ? '.' + rsltArr[1] : '')
}

export function trimStartZero(str) {
  str = str.toString()
  for (let i = 0, l = str.length; i < l; i++) {
    if (
      str.length >= i + 1 &&
      str.substr(i, 1) === '0' &&
      str.substr(i + 1, 1) !==  '.'
    ) {
      str = str.slice(0, i) + 'x' + str.slice(i + 1)
    } else {
      break
    }
  }
  str = str.replace(/x/g, '')
  return str
}

export function trimEndZero(str) {
  str = str.toString()
  if (str === '') return str
  for (let i = -1, l = str.length; i >= -l; i--) {
    if (str.substr(i, 1) === '0') {
      str = str.slice(0, i) + 'x' + (i < -1 ? str.slice(i + 1) : '')
    } else {
      break
    }
  }
  str = str.replace(/x/g, '')
  return str
}

export function canBeSent(money, decimals) {
  const result = MtoP(money, decimals)
  if (result === false) return false
  const rsltArr = divideDecimalPoint(result)
  return rsltArr[0] !== '' && rsltArr[0] !== '0'
}

// memberCodeを取得
export function removeMemberCode() {
  sessionStorage.removeItem('login_member_code')
}

// リクエスト秘密鍵を取得
export function removeRequestSecretKey() {
  sessionStorage.removeItem('request_secret_key');
}

export function getErrorMessage(type) {
  let errorMessage
  switch (type) {
    case config.ERR_EMPTY:
      errorMessage = 'Error: Cannot be read because it is empty.'
      break
    case config.ERR_MEMBER_CODE_EMPTY:
      errorMessage = 'Error: Member code is blank.'
      break
    case config.ERR_MNEMONIC_EMPTY:
      errorMessage = 'Error: Mnemonic is blank.'
      break
    case config.ERR_SEND_OWN:
      errorMessage = 'Error: Cannot send yourself.'
      break
    case config.ERR_INVALID_MEMBER_CODE:
      errorMessage = 'Error: Invalid member code.'
      break
    case config.ERR_INVALID_MNEMONIC:
      errorMessage = 'Error: Invalid mnemonic.'
      break
    case config.ERR_INVALID_FILE:
      errorMessage = 'Error: Wrong file type.'
      break
    case config.ERR_NOT_QR_CODE:
      errorMessage = 'Error: QR code is not included in this file.'
      break
    case config.ERR_INVALID_USER_INFO:
      errorMessage = 'Error: Invalid user information.'
      break
    default:
      errorMessage = 'Error: Read Error'
  }
  return errorMessage
}

export function getUTCDate() {
  return new DateWithOffset(0)
}

export function getUTCTimestamp() {
  return getTimestamp(getUTCDate())
}

// yyyymmddhhiiss形式で時間を取得
export function getTimestamp(date) {
  if (typeof date == 'undefined') date = new Date()
  return date.getFullYear() +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    ('0' + date.getDate()).slice(-2) +
    ('0' + date.getHours()).slice(-2) +
    ('0' + date.getMinutes()).slice(-2) +
    ('0' + date.getSeconds()).slice(-2);
}

export const applyStatus = {
  0: 'LimitExceeded',
  1: 'Apply',
  2: 'PreMovie',
  3: 'ConsentForm',
  4: 'AdvanceSurvey',
  5: 'Register',
  6: 'RegisterConfirm',
}

export function resumeFromStatus(self) {
  try {
    if (self.$router.currentRoute.value.name == 'AuthRegister') return

    const completed = localStorage.getItem('preAnswerComplete')
    const status = localStorage.getItem('currentStatus')
    if (!completed && status) {
      self.$router.replace({name: applyStatus[status]})
    }
  } catch(e) {
    console.log(e)
  }
}

export function movePreviousStatus(self, isLogin) {
  if (isLogin) {
    const path = sessionStorage.getItem('referrer_path')
    self.$router.replace({path})
  } else {
    const status = localStorage.getItem('currentStatus')
    if (status && status > 1) {
      self.$router.replace({name: applyStatus[status - 1]})
    }
  }
}

export async function checkStatus(self, status) {
  // 既に投票した場合、完了画面に遷移
  if (localStorage.getItem('preAnswerComplete')) {
    self.$router.replace({name: 'PreAnswerComplete'})
    return
  }

  let yourStatus = 1
  // 応募画面を同意している場合
  const applyFlag = localStorage.getItem('applyFlag')
  if (applyFlag == '1') {
    yourStatus = 2
  }
  if (yourStatus == 2) {
    // 動画を見ている場合
    const movieFlag = localStorage.getItem('movieFlag')
    if (movieFlag == '1') {
      yourStatus = 3
    }
  }
  if (yourStatus == 3) {
    // 同意画面での同意がある場合
    const consentFlag = localStorage.getItem('consentFlag')
    if (consentFlag == '1') {
      yourStatus = 4
    }
  }
  if (yourStatus == 4) {
    // 事前アンケートのデータがある場合
    const answerInputJson = localStorage.getItem('answerInputJson')
    if (answerInputJson !== null) {
      yourStatus = 5
    }
  }
  if (yourStatus == 5) {
    const formDataJson = localStorage.getItem('registerFormDataJson')
    // フォーム入力データがある場合
    if (formDataJson !== null) {
      yourStatus = 6
    }
  }

  if (status > yourStatus) {
    self.$router.replace({name: applyStatus[yourStatus]})
    return
  }

  // 一時IDチェック
  const res = await checkTempId(status)
  if (res == 2) {
    self.$router.replace({name: applyStatus[0]})
  } else if (res == 3) {
    localStorage.removeItem('applyFlag')
    localStorage.removeItem('movieFlag')
    localStorage.removeItem('consentFlag')
    localStorage.removeItem('answerInputJson')
    localStorage.removeItem('registerFormDataJson')
    localStorage.removeItem('tempId')
    localStorage.removeItem('currentStatus')
    localStorage.setItem('preAnswerComplete', '1')
    alert('既に応募は完了しています。')
    self.$router.push({name: 'PreAnswerComplete'})
    return
  } else if (res == 4) {
    localStorage.removeItem('tempId')
    alert('通信エラーが発生しました。ブラウザを再読み込みしてください。')
  }

  localStorage.setItem('currentStatus', status)
}
