import router from './router'
import store from './store'
import { checkLogin } from '@/api/login.js'
import { getMemberCode, getRequestSecretKey } from '@/utils/common.js'
import { makeRequestByAes } from '@/utils/make-request-data.js'
import { validSecretkey } from '@/utils/validate'

router.beforeEach(async(to, from, next) => {
  const names = ['Login', 'KeyLogin', 'Questionnaire']
  const whiteList = router.options.routes.reduce(
    (l, rt) => names.indexOf(rt.name) !== -1 && rt.children ? l.concat(rt.children.map(r => r.name)) : l,
    [],
  )

  // リファラ
  const referrer = sessionStorage.getItem('referrer_path')
  // リファラをsessionStorageに保存
  sessionStorage.setItem('referrer_path', to.path)

  // determine whether the user has logged in
  const memberCode = getMemberCode()
  const requestSecretKey = getRequestSecretKey()

  if (requestSecretKey && validSecretkey(requestSecretKey)) {
    if (whiteList.indexOf(to.name) !== -1) {
      // if is logged in, redirect to the home page
      if (!referrer || confirm('非ログインページに移動しようとしています。ログアウトしますか？')) {
        await store.dispatch('member/resetToken')
        next({ path: to.path })
      } else {
        next({ path: referrer })
      }
    } else {
      // eslint-disable-next-line no-undef
      const hasCode = store.getters.code
      if (hasCode) {
        next()
      } else {
        try {
          const data = makeRequestByAes(memberCode, requestSecretKey, {
            member_code: memberCode,
            name: to.name,
          })
          const result = await checkLogin(data)
          if (result && result.value) {
            sessionStorage.setItem('check_login_time', new Date(result.value))
            await store.dispatch('member/setToken', memberCode)
            next({ ...to, replace: true })
          } else {
            throw Error()
          }
        } catch (error) {
          await store.dispatch('member/resetToken')
          next('/login')
          // alert("You have been logged out.\nPlease log in again.")
          alert("ログアウトしています。再度ログインしてください。")
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.name) !== -1) {
      // in the free login whitelist, go directly
      await store.dispatch('member/resetToken')
      next()
    } else {
      // 未ログインならログインページへ
      next('/login')
    }
  }
})
