import { createApp } from 'vue'

import css from './assets/css/style.css'
import naive from './design'

import App from './App.vue'
import store from './store'
import router from './router'

import './permission'

createApp(App)
  .use(store)
  .use(router, css)
  .use(naive)
  .mount('#app')
