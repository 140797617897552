<template>
  <div id="nav">
    <!-- <navbar /> -->
    <app-main />
  </div>
</template>
<script>
import AppMain from './components/AppMain.vue'
// import Navbar from './components/Navbar.vue'

export default {
  name: 'Layout',
  components: {
    AppMain,
    // Navbar,
  },
}
</script>
