import axios from 'axios'
import store from '@/store'
import router from '@/router'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: ((process.env.VUE_APP_API_BASE_URL) ? process.env.VUE_APP_API_BASE_URL : undefined),
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 000, it is judged as an error.
    if (res.code !== '000' && typeof res === 'object') {
      // Message({
      //   message: res.msg || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    const errRes = error.response.data
    console.log(errRes)
    // Message({
    //   message: errRes.msg !== undefined ? errRes.msg : error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })

    if (errRes.code === 'ERR005') {
      // to re-login
      // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //   confirmButtonText: 'Re-Login',
      //   cancelButtonText: 'Cancel',
      //   type: 'warning'
      // }).then(() => {
        store.dispatch('member/resetToken').then(() => {
          router.push('/keyLogin')
        })
      // })
    }
    return Promise.reject(error)
  }
)

export default service
