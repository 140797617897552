<template>
  <div id="nav">
    <!-- Page Content -->
    <div class="container" id="container">
      <div class="col-lg-12 mb-12 mt-5">
        <p>ページが見つかりませんでした。</p>
        <p>恐れ入りますが、URLを確認して再度アクセスしてください。</p>
      </div>
    </div>
  </div>
</template>
