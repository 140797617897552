import request from '@/utils/request'
import vernamCipher from 'vernam_cipher_on_node/vernam_cipher';
import { getRandomValues } from '@/utils/cipher'
import { getTimestamp } from '@/utils/common'

export function firstRequest(cipher) {
  return request({
    url: '/api/request/firstRequest',
    method: 'post',
    data: {
      cipher: cipher,
    }
  })
}
export function secondRequest(url, uuid, cipher) {
  return request({
    url: url,
    method: 'post',
    data: {
      uuid: uuid,
      cipher: cipher,
    }
  })
}
/**
 * 中間復号化API (多重暗号受け側の1回目)
 *
 * @param data {object} 送信データ
 * @return APIレスポンス
 */
export function intermediateDecrypt(uuid, cipher) {
  return request({
    url: '/api/request/intermediateDecryptRequest',
    method: 'post',
    data: {
      uuid: uuid,
      cipher: cipher,
    }
  })
}

/**
 * 多重暗号通信
 *
 * @param url {string} 送信先URL
 * @param data {object} 送信データ
 * @returns APIレスポンス
 */
export async function sendMultipleCipher(url, data) {
  // データをバーナム暗号化
  const cipheredSendData = vernamCipher.encryption(JSON.stringify(data))
  // 多重暗号通信で送付 1回目
  const firstResponse = await firstRequest(cipheredSendData.cipher)
  // 暗号文を中間復号化
  const intermediateCipherSendData = vernamCipher.intermediateDecrypt(firstResponse.value.cipher, cipheredSendData.cipherKey)
  // 多重暗号通信で送付 2回目
  return await secondRequest(url, firstResponse.value.uuid, intermediateCipherSendData)
}

/**
 * APIリクエストした後、サーバ側から多重暗号通信を受け取る
 *
 * @param url {string} 送信先URL
 * @param data {object} 送信データ
 * @returns APIレスポンス
 */
export async function sendMultipleCipherFromServer(url, data) {
  const firstRequest = await request({
    url: url,
    method: 'post',
    data: data
  })

  if (!firstRequest || !firstRequest.value || !firstRequest.value.cipher) {
    return false
  }

  // 中間暗号処理
  const multipleCipheredRequestData = vernamCipher.intermediateEncrypt(firstRequest.value.cipher)

  // 中間復号化API
  const secondResponse = await intermediateDecrypt(
    firstRequest.value.randomNum,
    multipleCipheredRequestData.cipher,
  )

  return vernamCipher.decryption(secondResponse.value.cipher, multipleCipheredRequestData.cipherKey)
}

/**
 * APIエラー表示
 *
 * @param error エラーオブジェクト
 * @returns void
 */
export function apiError(error) {
  if (error.response && error.response.data) {
    const data = error.response.data
    if (data.message) {
      alert(data.message)
    } else {
      alert('エラーが発生しました。大変申し訳ありませんが、時間をおいて再度やり直してください。')
    }
  }
}

export function sendTempIdAndCheck(data) {
  return request({
    url: '/api/register/sendTempIdAndCheck',
    method: 'post',
    data,
  })
}

/**
 * 一時ID確認
 * @returns 1: 正常 2: 受付人数超過 3: 登録済み 4: その他エラー
 */
export async function checkTempId(st) {
  // 一時IDがあるか確認
  let tempId = localStorage.getItem('tempId')
  if (!tempId) {
    // なければ一時IDを作成
    tempId = getRandomValues(50) + getTimestamp()
    localStorage.setItem('tempId', tempId)
  }
  let status = 1
  // 一時IDをチェック
  const res = await sendTempIdAndCheck({
    id: tempId,
    status: st,
  }).catch(e => {
    if (e.response.data.code == 'ERR004') {
      status = 2
    }
  })
  if (res && res.value && res.value.code) {
    status = 3
  }

  if ((!res || !res.value) && status == 1) {
    status = 4
  }
  return status
}
