export const config = {
  SITE_NAME: '口内細菌・唾液と幸福度調査',
  VIDEO_ID: 'Bs0nNwbgyHM',
  VIDEO_ID2: 'lTeIicEOBQA',
  MAXDECIMALS: 18,
  WALLET_HISTORY_NUM: 5,
  MNEMONIC_WORDS_NUM: 12,
  TRANSFER_HISTORY_NUM: 10,

  ERR_EMPTY: 1,
  ERR_MNEMONIC_EMPTY: 2,
  ERR_MEMBER_CODE_EMPTY: 3,
  ERR_INVALID_MEMBER_CODE: 11,
  ERR_INVALID_MNEMONIC: 12,
  ERR_INVALID_FILE: 13,
  ERR_INVALID_USER_INFO: 14,
  ERR_NOT_QR_CODE: 21,
  ERR_SEND_OWN: 31,

  ERRCODE_ALREADY_ANSWERED: 'ERR101',

  ANSWER_TYPE:  {
    TEXT: 1,
    RADIO: 2,
    CHECK: 3,
  },
  ERROR_TYPE: {
    REQUIRED: 1,
    INVALID: 2,
    EXCEED_LIMIT: 3,
  },
  ZIPCODE_API_URL: 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=',
}

export const CONST = {
  SURVEY: {
    ANSWER_TYPE: {
      ANY_ADDITIONAL_COMMENTS: 1,
      SINGLE_CHOICE: 2,
      MULTIPLE_CHOICE: 3,
      MULTIPLE_COMMENTS: 4,
    },
    ACTIVITY_TYPE: {
      RANGE: 1,
      ONE_TO_SEVEN: 2,
    },
  },
  MEMBER: {
    SEX: {
      MALE: 1,
      FEMALE: 2,
    },
    ACTIVATE_FLG: {
      DEACTIVATED: 0,
      ACTIVATED: 1,
      RESEARCH_SUBJECT: 2,
      PRODUCTION_AGREE: 3,
      PRODUCTION_COMPLETED: 4,
    },
  },
}
