import { removeMemberCode, removeRequestSecretKey } from '@/utils/common'
const state = {
  code: '',
}

const mutations = {
  SET_CODE: (state, code) => {
    state.code = code
  },
}

const actions = {
  // user login
  setToken({ commit }, memberCode) {
    return new Promise(resolve => {
      commit('SET_CODE', memberCode)
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeMemberCode()
      removeRequestSecretKey()
      commit('SET_CODE', '')
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
