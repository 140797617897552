import { sendMultipleCipher } from '@/api/common.js';
import request from '@/utils/request'

export function sendPin(data) {
  return sendMultipleCipher(
    '/api/mail/decryptSendPin',
    data,
  )
}

export function sendPinNormal(data) {
  return sendMultipleCipher(
    '/api/mail/decryptSendPinNormal',
    data,
  )
}

export function login(data) {
  return sendMultipleCipher(
    '/api/member/auth/decryptlogin',
    data,
  )
}

export function keyLogin(data) {
  return sendMultipleCipher(
    '/api/member/auth/decryptKeyLogin',
    data,
  )
}

export function intermediateDecrypt(data) {
  return request({
    url: '/api/request/intermediateDecryptRequest',
    method: 'post',
    data
  })
}

export function checkLogin(data) {
  return request({
    url: '/api/member/auth/isLogin',
    method: 'post',
    data
  })
}
