import crypto from 'crypto';

export function aesEncryption(textToEncrypt, passPhrase) {
  const iv = crypto.randomBytes(16)
  const key = crypto.pbkdf2Sync(passPhrase, 'salt', 1024, 32, 'sha256')
  const cipher = crypto.createCipheriv('aes-256-ctr', key, iv)

  const aesEcrypted = Buffer.concat([
    iv,
    cipher.update(textToEncrypt),
    cipher.final()
  ]).toString('base64')

  return aesEcrypted
}

export function aesDecryption(encryptionData, passPhrase) {
  const buff = Buffer.from(encryptionData, 'base64')
  const iv = buff.slice(0, 16)
  const encData = buff.slice(16)
  const key = crypto.pbkdf2Sync(passPhrase, 'salt', 1024, 32, 'sha256')
  const decipher = crypto.createDecipheriv('aes-256-ctr', key, iv)
  const decryptionData = Buffer.concat([
    decipher.update(encData),
    decipher.final()
  ]).toString('utf8')
  return decryptionData
}

/**
 * SHA-256暗号化する
 *
 * @param {string} text SHA-256暗号化する文字列
 * @returns {string} SHA-256暗号化された文字列
 */
export function createHashSha256(text) {
  return crypto.createHash('sha256')
    .update(text)
    .digest('hex')
    .toString();
}

/**
 * ランダム文字列を作成
 *
 * @param {integer} length 取得する文字列の長さ
 * @returns {string} ランダム文字列
 */
export function getRandomValues(length) {
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let rand_str = ''
  for (let i = 0; i < length; i++) {
    rand_str += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return rand_str
}
